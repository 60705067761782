import React, { Component } from 'react';

class IconPhpSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.49 49.49">         
            <circle className="language-icon-bg" cx="24.74" cy="24.74" r="24.74"/>
            <path className="language-icon-outline" d="M25,15.52a26.84,26.84,0,0,0-12.2,2.65c-3.17,1.65-5.3,4-5.3,6.83s2.13,5.18,5.3,6.83A26.84,26.84,0,0,0,25,34.48a26.84,26.84,0,0,0,12.2-2.65c3.17-1.65,5.3-4,5.3-6.83s-2.13-5.18-5.3-6.83A26.84,26.84,0,0,0,25,15.52ZM25,17a25.49,25.49,0,0,1,11.52,2.48C39.42,21,41,23,41,25s-1.62,4-4.52,5.54A25.49,25.49,0,0,1,25,33a25.49,25.49,0,0,1-11.52-2.48C10.58,29,9,27,9,25s1.62-4,4.52-5.54A25.49,25.49,0,0,1,25,17Zm-1.82,1.46-1.83,8.75h1.92l.92-4.38h1.53a1.36,1.36,0,0,1,1,.24,1.11,1.11,0,0,1,.1.92l-.7,3.22H28l.76-3.49a1.89,1.89,0,0,0-.36-1.82,3.39,3.39,0,0,0-2.23-.53h-1.7l.6-2.91Zm-8.39,2.91-1.48,8h1.93l.39-2.19h1.2c2.51,0,3.88-.58,4.39-2.74a2.37,2.37,0,0,0-2.54-3.1Zm15.31,0-1.47,8h1.92l.39-2.19h1.2c2.51,0,3.88-.58,4.39-2.74A2.37,2.37,0,0,0,34,21.35ZM16.45,22.81H18c1.25,0,1.51.56,1.44,1.19-.16,1.61-1.17,1.73-2.24,1.73H15.9Zm15.31,0h1.5c1.25,0,1.51.56,1.45,1.19-.17,1.61-1.17,1.73-2.25,1.73H31.21Z" transform="translate(-0.26 -0.26)"/>
        </svg>
        </>
    );
  }
}

export default IconPhpSVG;