import React, { Component } from 'react';

class IconDatabaseSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.82 195.82">
            <circle className="icon-background" cx="97.91" cy="97.91" r="97.91"/>
            <path className="icon-highlight" d="M102,47.44c-30.42,0-54.26,9.48-54.26,21.61V128c0,12.12,23.84,21.61,54.26,21.61s54.28-9.48,54.28-21.6V69.05C156.27,56.93,132.41,47.44,102,47.44Z"/>
            <path className="icon-highlight-database" d="M94.72,45.21c-30.42,0-54.26,9.49-54.26,21.61v58.91c0,12.12,23.84,21.61,54.26,21.61S149,137.85,149,125.73V66.82C149,54.7,125.14,45.21,94.72,45.21Z" transform="translate(-2.09 -2.57)"/>
            <path className="icon-outline" d="M104.21,45.21C73.79,45.21,50,55.45,50,68.53v63.59c0,13.07,23.84,23.31,54.26,23.31s54.25-10.24,54.25-23.31V68.53C158.46,55.45,134.63,45.21,104.21,45.21Zm0,4.24c30,0,50.08,9.86,50.08,19.08S134.16,87.6,104.21,87.6,54.12,77.74,54.12,68.53,74.25,49.45,104.21,49.45Zm0,4.24A104.4,104.4,0,0,0,79,56.51a2.12,2.12,0,0,0-1.51,2.58,2.08,2.08,0,0,0,2,1.6,1.77,1.77,0,0,0,.5-.07,101.86,101.86,0,0,1,24.16-2.69c2.77,0,5.53.09,8.21.27a2.05,2.05,0,0,0,2.22-2A2.11,2.11,0,0,0,112.7,54C109.93,53.79,107.07,53.69,104.21,53.69Zm18.3,1.41a2.08,2.08,0,0,0-2.39,1.75,2.12,2.12,0,0,0,1.73,2.44c2.3.37,4.48.82,6.51,1.33a1.79,1.79,0,0,0,.51.07,2.1,2.1,0,0,0,2-1.6,2.13,2.13,0,0,0-1.51-2.58C127.24,56,124.93,55.49,122.51,55.1ZM54.12,77.66c8.12,8.41,27.25,14.18,50.09,14.18s42-5.77,50.08-14.18V89.72c0,9.22-20.13,19.08-50.08,19.08S54.12,98.94,54.12,89.72Zm0,21.2C62.24,107.27,81.37,113,104.21,113s42-5.77,50.08-14.18v12.06c0,3.67-3.19,7.46-9,10.67a2.15,2.15,0,0,0-.84,2.87,2.08,2.08,0,0,0,1.84,1.11,2.12,2.12,0,0,0,1-.25,29.27,29.27,0,0,0,7-5.22v12c0,9.21-20.12,19.07-50.08,19.07s-50.09-9.86-50.09-19.07V120c6.71,7,21,12.26,39.35,13.8h.17a2.12,2.12,0,0,0,.17-4.24c-22.62-1.88-39.69-9.9-39.69-18.64Zm81.39,22.66a2.1,2.1,0,0,0-2.09,2.12v4.24a2.09,2.09,0,1,0,4.18,0v-4.24A2.1,2.1,0,0,0,135.51,121.52Zm-10.43,2.32A2.11,2.11,0,0,0,123,126v4.24a2.09,2.09,0,1,0,4.17,0V126A2.11,2.11,0,0,0,125.08,123.84Zm-10.44,1.49a2.11,2.11,0,0,0-2.09,2.12v4.24a2.09,2.09,0,1,0,4.18,0v-4.24A2.11,2.11,0,0,0,114.64,125.33Zm-10.43.43a2.1,2.1,0,0,0-2.09,2.12v4.24a2.09,2.09,0,1,0,4.17,0v-4.24A2.09,2.09,0,0,0,104.21,125.76Z" transform="translate(-2.09 -2.57)"/>
        </svg>
        </>
    );
  }
}

export default IconDatabaseSVG;
