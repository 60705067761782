import React, { Component } from 'react';

class IconJavaScriptSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.49 49.49">
            <circle className="language-icon-bg" cx="24.74" cy="24.74" r="24.74"/>
            <path className="language-icon-outline" d="M12.78,11A1.78,1.78,0,0,0,11,12.78V37.22A1.78,1.78,0,0,0,12.78,39H37.22A1.78,1.78,0,0,0,39,37.22V12.78A1.78,1.78,0,0,0,37.22,11Zm0,1.33H37.22a.45.45,0,0,1,.45.45V37.22a.45.45,0,0,1-.45.45H12.78a.45.45,0,0,1-.45-.45V12.78A.45.45,0,0,1,12.78,12.33ZM23.67,23.67v8.38c0,1.29-.49,1.62-1.34,1.62a2.19,2.19,0,0,1-1.9-1.27l-2.1,1.27a4.69,4.69,0,0,0,4.16,2.66c2.28,0,3.84-1.21,3.84-3.87V23.67Zm8.3,0A3.4,3.4,0,0,0,28.24,27c0,2.07,1.22,3,3.06,3.83l.63.27c1.16.51,1.74.82,1.74,1.69s-.56,1.26-1.61,1.26c-1.26,0-1.85-.86-2.39-1.75l-2,1.34a4.64,4.64,0,0,0,4.45,2.66c2.41,0,4.21-1.25,4.21-3.54,0-2.13-1.22-3.07-3.38-4l-.63-.27c-1.1-.47-1.57-.78-1.57-1.54A1.1,1.1,0,0,1,32,25.89,1.72,1.72,0,0,1,33.6,27l2-1.27A3.75,3.75,0,0,0,32,23.67Z" transform="translate(-0.26 -0.26)"/>
        </svg>
        </>
    );
  }
}

export default IconJavaScriptSVG;