import React, { Component } from 'react';

class StephAvatarSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 104 104" id="steph-avatar">
            <defs>
                <linearGradient id="linear-gradient" x1="44.93" y1="33.69" x2="61.51" y2="40.13" gradientUnits="userSpaceOnUse">
                    <stop offset="0.42" stopColor="#cfb26c"/>
                    <stop offset="0.47" stopColor="#cbb270"/>
                    <stop offset="0.73" stopColor="#edd99a"/>
                    <stop offset="0.99" stopColor="#ecd798"/>
                </linearGradient>
                <clipPath id="clip-path" transform="translate(4.9 -0.99)">
                    <circle className="cls-1" cx="50" cy="50" r="49.01"/>
                </clipPath>
                <clipPath id="clip-path-2" transform="translate(4.9 -0.99)">
                    <path className="cls-2" d="M102.6,120.75c-3.63-8-9.72-22.65-13.31-30.64C87.1,85.25,80.15,74.66,75.9,71.46c-3.48-2.63-11.74-3.46-16.08-4-2.45-.28-9.88-3.7-9.88-3.7s-7.12,3.62-9.49,3.7c-4.13.15-11.84.89-15.28,3.17C19.68,74.3,13.18,87.05,10,92.81,6.06,100-2.9,121.08-2.9,121.08a53.47,53.47,0,0,0-2,6.26,12.1,12.1,0,0,0,1.44,6.19C.49,140,13.83,156.72,13.83,156.72H86.52S99,139.6,102.92,133.14c.77-1.26,2-4.07,2-5.54C104.82,126,103.27,122.24,102.6,120.75Zm-87.29-4.83,6-10.07,6.47,11.92ZM73,118l6.47-11.93L85,116.14Z"/>
                </clipPath>
                <clipPath id="clip-path-3" transform="translate(4.9 -0.99)">
                    <path className="cls-3" d="M79.4,99.2s1.06,7.86.86,9.24c-.45,3-4.81,7.3-4.81,7.3H25s-4.37-4.27-4.81-7.3c-.2-1.38.86-9.24.86-9.24L8.82,92.41S14.88,80.55,17.52,77a33.9,33.9,0,0,1,6-5.6c1.26-.82,4.61-2.37,4.61-2.37L32,68.24c0,.39,0,1.7,0,2.08,0,13.86,7.48,17.29,17.85,17.29S67.7,84,67.7,70.17c0-.36,0-1.65,0-2,0,0,3.18,1.09,4.21,1.32a25.07,25.07,0,0,1,5.54,2.37A29.28,29.28,0,0,1,83,77c2.74,3.48,8.7,15.42,8.7,15.42Z"/>
                </clipPath>
                <clipPath id="clip-path-4" transform="translate(4.9 -0.99)">
                    <path className="cls-2" d="M43.33,59A8.83,8.83,0,0,1,43,64.56a9.11,9.11,0,0,1-3,3.7,10.7,10.7,0,0,1-3,1.65l24.37.37a6.65,6.65,0,0,1-3.22-2.38,9.2,9.2,0,0,1-1.19-3.58,26.7,26.7,0,0,1-.48-5Z"/>
                </clipPath>
                <radialGradient id="radial-gradient" cx="71.25" cy="45.21" r="0.4" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#bcbec0"/>
                    <stop offset="0.19" stopColor="#b5b7b9"/>
                    <stop offset="0.48" stopColor="#a0a2a4"/>
                    <stop offset="0.83" stopColor="#7f8083"/>
                    <stop offset="1" stopColor="#6d6e71"/>
                </radialGradient>
                <radialGradient id="radial-gradient-2" cx="37.6" cy="45.41" r="0.4" xlinkHref="#radial-gradient"/>
                <clipPath id="clip-path-5" transform="translate(4.9 -0.99)">
                    <path className="cls-4" d="M60.89,60.81a8.46,8.46,0,0,1-.41-4c.3-1.9,1.1-2.71,2.58-5.44a45.21,45.21,0,0,0,2.2-4.72c.24.36.67.87,1.15.83a.34.34,0,0,0,.11,0c.32-.1.82-.76,2.54-5.22,0,0,.09-.23.25-.69.35-1,.54-1.54.67-2a5.06,5.06,0,0,0,.15-2.66c-.11-.42-.4-1.49-1.16-1.68s-1.44.49-1.77.9c-.14.19-.13.24-.18.24-.71.09-1.51-9.9-4.73-12.28a35.68,35.68,0,0,0-4-3.11h0l-.48-4.77c1.24-.3,6.4-1.43,10.74,1.55s5,8,5.48,11.69A26,26,0,0,1,71.6,43.94a57.1,57.1,0,0,0-4.18,8.7,21.48,21.48,0,0,0-1.08,3.45c-.26,1.47-.52,6.44,5,12.74a12.64,12.64,0,0,1-8-3.66A10.46,10.46,0,0,1,60.89,60.81Z"/>
                </clipPath>
                <linearGradient id="linear-gradient-3" x1="40.83" y1="40.46" x2="55.65" y2="45.4" gradientUnits="userSpaceOnUse">
                    <stop offset="0.01" stopColor="#ecd798"/>
                    <stop offset="0.1" stopColor="#edd99a"/>
                    <stop offset="0.48" stopColor="#cfb26c"/>
                </linearGradient>
                <linearGradient id="linear-gradient-4" x1="75.94" y1="55.11" x2="75.94" y2="55.11" xlinkHref="#linear-gradient-3"/>
            </defs>
            <circle className="cls-1" cx="54.9" cy="49.01" r="49.01"/>
            <g className="cls-5">
                <path className="cls-2" d="M102.6,120.75c-3.63-8-9.72-22.65-13.31-30.64C87.1,85.25,80.15,74.66,75.9,71.46c-3.48-2.63-11.74-3.46-16.08-4-2.45-.28-9.88-3.7-9.88-3.7s-7.12,3.62-9.49,3.7c-4.13.15-11.84.89-15.28,3.17C19.68,74.3,13.18,87.05,10,92.81,6.06,100-2.9,121.08-2.9,121.08a53.47,53.47,0,0,0-2,6.26,12.1,12.1,0,0,0,1.44,6.19C.49,140,13.83,156.72,13.83,156.72H86.52S99,139.6,102.92,133.14c.77-1.26,2-4.07,2-5.54C104.82,126,103.27,122.24,102.6,120.75Zm-87.29-4.83,6-10.07,6.47,11.92ZM73,118l6.47-11.93L85,116.14Z" transform="translate(4.9 -0.99)"/>
                <g className="cls-6">
                    <path className="cls-7" d="M47.63,65.06a3.12,3.12,0,0,1-1.06,2.77C44.64,69.47,38.65,70,36.72,70s-8.18.53-8.18.53l-1-3.31,19.65-2.6" transform="translate(4.9 -0.99)"/>
            </g>
            <path className="cls-3" d="M79.4,99.2s1.06,7.86.86,9.24c-.45,3-4.81,7.3-4.81,7.3H25s-4.37-4.27-4.81-7.3c-.2-1.38.86-9.24.86-9.24L8.82,92.41S14.88,80.55,17.52,77a33.9,33.9,0,0,1,6-5.6c1.26-.82,4.61-2.37,4.61-2.37L32,68.24c0,.39,0,1.7,0,2.08,0,13.86,7.48,17.29,17.85,17.29S67.7,84,67.7,70.17c0-.36,0-1.65,0-2,0,0,3.18,1.09,4.21,1.32a25.07,25.07,0,0,1,5.54,2.37A29.28,29.28,0,0,1,83,77c2.74,3.48,8.7,15.42,8.7,15.42Z" transform="translate(4.9 -0.99)"/>
            <g className="cls-8">
                <polygon className="cls-9" points="84.8 99.44 89.41 102.75 81.94 75.48 84.8 99.44"/>
                <polygon className="cls-9" points="25.64 99.4 21.02 102.7 28.5 75.44 25.64 99.4"/>
            </g>
            <path className="cls-10" d="M37,56.42c-.69,2.27,1.1,5.43,2.75,7.15a12.07,12.07,0,0,0,4.53,2.75c3.63,1.42,8.6,3.38,13,.83a11.07,11.07,0,0,0,4.89-6.8c.18-.77.85-3.72-.36-5.84C58.18,48.19,38.76,50.46,37,56.42Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-10" d="M65.63,64c.52,2-3.71,4.69-4.41,5.13A6.88,6.88,0,0,0,59.91,64c-1.53-1.73-3.82-1.73-3.78-2C56.2,61.58,64.93,61.35,65.63,64Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-11" d="M39.39,61.34" transform="translate(4.9 -0.99)"/>
            <path className="cls-10" d="M38.89,63.74a20.45,20.45,0,0,1-2.62,2A17.65,17.65,0,0,1,31,68,11.2,11.2,0,0,0,33,60.75C32.27,55,26.22,53,26.84,49.66c.55-2.95,5.91-5.46,9.43-4.17C42.33,47.71,42.06,61.06,38.89,63.74Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M43.33,59A8.83,8.83,0,0,1,43,64.56a9.11,9.11,0,0,1-3,3.7,10.7,10.7,0,0,1-3,1.65l24.37.37a6.65,6.65,0,0,1-3.22-2.38,9.2,9.2,0,0,1-1.19-3.58,26.7,26.7,0,0,1-.48-5Z" transform="translate(4.9 -0.99)"/>
            <g className="cls-12">
                <polygon className="cls-7" points="44.3 66.19 52.66 64.43 52.83 59.45 44.3 57.37 44.3 66.19"/><path className="cls-7" d="M47.76,65a3.12,3.12,0,0,1-1.06,2.77c-1.93,1.63-7.92,2.15-9.85,2.15s-8.18.53-8.18.53l-1-3.31,19.65-2.6" transform="translate(4.9 -0.99)"/>
                <path className="cls-13" d="M60.41,59c0,2.1-5.47,4.06-8.13,4.06s-7.34-2-7.34-4.06,2.17-3.8,4.83-3.8S60.41,56.85,60.41,59Z" transform="translate(4.9 -0.99)"/>
            </g>
            <path className="cls-7" d="M31.93,36c-1-1-1.54-.94-1.79-.87-1,.24-1.24,2-1.29,2.29-.24,1.46.24,2.29,1.62,5.82,1.18,3,1.51,4.13,2.22,4.17s1.18-.84,1.41-1.21C36,43.09,34.3,38.33,31.93,36Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-14" d="M29.65,38.74c.14-.64,0-1.89.77-2.55" transform="translate(4.9 -0.99)"/><path className="cls-14" d="M31.31,37a2,2,0,0,0-1-1,3.56,3.56,0,0,1-.38-.09" transform="translate(4.9 -0.99)"/>
            <path className="cls-7" d="M70.26,37.45c-.05-.32-.33-2.05-1.29-2.29-.26-.07-.8-.09-1.79.87-2.37,2.3-4.08,7.06-2.17,10.2.23.37.76,1.24,1.4,1.21s1-1.16,2.22-4.17C70,39.74,70.49,38.91,70.26,37.45Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M67.4,34.38v0Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M67.4,34.55v.05a.28.28,0,0,1,0,.09v-.14Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M67.4,23.73q0,8.83,0,10.67C66.93,35.88,66,38.21,59,45.08a11.23,11.23,0,0,1,1.7,8.36l6.72-18.87v.31a38.71,38.71,0,0,1-1.53,9.9,39.27,39.27,0,0,1-6,11.89,17.52,17.52,0,0,1-2,2.36,12.32,12.32,0,0,1-2.07,1.72,11.22,11.22,0,0,1-13-.6h0a11.32,11.32,0,0,1-2.19-2.29,35.48,35.48,0,0,1-8.82-23.48L39,54.93a11.27,11.27,0,0,1-.59-3.6,11.12,11.12,0,0,1,1.88-6.21,18.57,18.57,0,0,1-8.49-10.74c0-.13-.08-.26-.11-.39V24.13A18.18,18.18,0,0,1,33.1,20.6a18.65,18.65,0,0,1,4.26-5.47c3.27-2.88,7.5-1.49,12.2-1.49s6.28,1.79,9.51,4.53c1.87,1.58,5.95.24,7.07,2.43A19,19,0,0,1,67.4,23.73Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M67.39,34.4v.17L60.67,53.44A11.23,11.23,0,0,0,59,45.08C66,38.21,66.93,35.88,67.39,34.4Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-2" d="M39,54.93,31.73,34.38V34c0,.13.07.26.11.39a18.57,18.57,0,0,0,8.49,10.74,11.12,11.12,0,0,0-1.88,6.21A11.27,11.27,0,0,0,39,54.93Z" transform="translate(4.9 -0.99)"/>
            <line className="cls-15" x1="43.48" y1="53.82" x2="43.48" y2="53.82"/>
            <line className="cls-15" x1="36.57" y1="33.39" x2="36.57" y2="33.39"/>
            <path className="cls-14" d="M31.78,34.38" transform="translate(4.9 -0.99)"/>
            <path className="cls-7" d="M36.33,33.11c.33,2.81.71,2.9.65,4.61-.11,3.39-1.64,4-1.42,6.61.06.74.84,2.16,2.4,5,1.43,2.58,2.47,4.24,3.94,6.56a24.78,24.78,0,0,0,2.38,3.46A11.73,11.73,0,0,0,50.6,62.5a12.38,12.38,0,0,1-6.08-1.15c-2.64-1.27-4.15-3.25-5.84-5.49a30.38,30.38,0,0,1-2.75-4.41,23.76,23.76,0,0,1-2.85-7.52c-.62-3.88.4-3.6.28-8.23s-.7-5.82.56-8.59c2.83-6.27,9.73-8.28,9.71-8.32S37.73,21,36.29,26C35.76,27.89,35.93,29.75,36.33,33.11Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-7" d="M76.42,65" transform="translate(4.9 -0.99)"/>
            <circle className="cls-16" cx="71.29" cy="45.26" r="0.4"/>
            <circle className="cls-17" cx="37.65" cy="45.45" r="0.4"/>
            <path className="cls-18" d="M57.76,16.14c-.53-.8-4.36-6.38-11.3-7a15,15,0,0,0-14,6.91,11.89,11.89,0,0,0-4.42,3.22,12.19,12.19,0,0,0-2.62,6,15.36,15.36,0,0,0,1.43,9.42,10.32,10.32,0,0,1-1.55,4.18c-1.15,1.74-2.15,2-3.34,3.57A11,11,0,0,0,20,48c-.1,1-.54,5.28,2,8.11,1.61,1.77,2.61,1,5.85,3.7a7.78,7.78,0,0,1,3,3.94,6.49,6.49,0,0,1-1.11,5c1.11-.57,4.2-2.37,4.77-5.48.34-1.85-.51-3.66-2.14-7.28-1.52-3.36-2-3.37-2.15-4.77C30,48.38,31.93,47.49,33,43.92c1-3.28-.23-4,0-9.05.24-4.31.4-7.11,2.28-9.85,3.71-5.4,11.19-6.27,11.77-6.33l.5,0a19.31,19.31,0,0,1,10.62,2.26Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-4" d="M60.89,60.81a8.46,8.46,0,0,1-.41-4c.3-1.9,1.1-2.71,2.58-5.44a45.21,45.21,0,0,0,2.2-4.72c.24.36.67.87,1.15.83a.34.34,0,0,0,.11,0c.32-.1.82-.76,2.54-5.22,0,0,.09-.23.25-.69.35-1,.54-1.54.67-2a5.06,5.06,0,0,0,.15-2.66c-.11-.42-.4-1.49-1.16-1.68s-1.44.49-1.77.9c-.14.19-.13.24-.18.24-.71.09-1.51-9.9-4.73-12.28a35.68,35.68,0,0,0-4-3.11h0l-.48-4.77c1.24-.3,6.4-1.43,10.74,1.55s5,8,5.48,11.69A26,26,0,0,1,71.6,43.94a57.1,57.1,0,0,0-4.18,8.7,21.48,21.48,0,0,0-1.08,3.45c-.26,1.47-.52,6.44,5,12.74a12.64,12.64,0,0,1-8-3.66A10.46,10.46,0,0,1,60.89,60.81Z" transform="translate(4.9 -0.99)"/>
            <g className="cls-19">
                <path className="cls-18" d="M67.4,32c0-7-1.34-11.22-6.5-11.64C57,20,53.77,24.2,53.77,31s2.6,13.22,6.52,13.22S67.4,38.7,67.4,32Z" transform="translate(4.9 -0.99)"/>
            </g>
            <path className="cls-20" d="M67.37,35.76" transform="translate(4.9 -0.99)"/>
            <path className="cls-21" d="M58.24,20.91A12.37,12.37,0,0,0,51.61,16c-5.9-1.64-10.86,2.38-11.93,3.25C37.15,21.32,35,25.49,35,25.52h0a24,24,0,0,0-1,7.68c.13,3.82,1.1,4.39.59,7.28-.28,1.57-.87,4.9-3.15,7.32a4.08,4.08,0,0,0-1.07,1.55c-.48,1.31.07,2.65,1,4.61,1.52,3.33,2.43,3.8,2.87,5.1.59,1.76.2,4.68-4.51,9.68A11.16,11.16,0,0,0,30,61.35c-1.71-4.68-6.26-4.61-7.45-8.87-1-3.58,1.06-7.77,2.39-10.5,1-2,2-3.37,2.48-6a19.11,19.11,0,0,0,0-5A20.65,20.65,0,0,1,30.9,19.55a18.18,18.18,0,0,1,8-7.06c.8-.34,7.62-3.17,14.31.31a15.75,15.75,0,0,1,4.56,3.61Z" transform="translate(4.9 -0.99)"/>
            <path className="cls-22" d="M75.94,55.11" transform="translate(4.9 -0.99)"/>
        </g>
        </svg>
        </>
    );

}
}

export default StephAvatarSVG;
