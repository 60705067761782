import React, { Component } from 'react';

class CourseProjectSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="course-svg">
            <path d="M 12 2 C 11.90325 1.999875 11.806391 2.0149687 11.712891 2.0429688 L 2.671875 4.7558594 C 2.142875 4.9138594 1.841 5.471 2 6 C 2.159 6.529 2.7151406 6.830875 3.2441406 6.671875 L 12 4.0449219 L 20.755859 6.671875 C 21.284859 6.829875 21.841 6.529 22 6 C 22.159 5.471 21.857125 4.9148594 21.328125 4.7558594 L 12.287109 2.0429688 C 12.193609 2.0144688 12.09675 2.000125 12 2 z M 3.0664062 9.0234375 C 2.4894062 8.9974375 2 9.4454375 2 10.023438 L 2 19.021484 C 2 19.565484 2.4364687 19.998484 2.9804688 20.021484 C 9.2814687 20.281484 12 23 12 23 C 12 23 14.718531 20.280531 21.019531 20.019531 C 21.563531 19.996531 22 19.565484 22 19.021484 L 22 10.021484 C 22 9.4434844 21.510594 8.9974375 20.933594 9.0234375 C 14.693594 9.3064375 12 12 12 12 C 12 12 9.3064063 9.3054375 3.0664062 9.0234375 z">
            </path>
        </svg>
        </>
    );
  }
}

export default CourseProjectSVG;
