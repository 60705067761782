import React, { Component } from 'react';

class IconCsharpSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.49 49.49">
            <circle className="language-icon-bg" cx="24.74" cy="24.74" r="24.74"/>
            <path className="language-icon-outline" d="M25,8.57A3,3,0,0,0,23.52,9l-12,6.76A2.9,2.9,0,0,0,10,18.24V31.76a2.9,2.9,0,0,0,1.49,2.52l12,6.76a3,3,0,0,0,3,0l12-6.76A2.89,2.89,0,0,0,40,31.76V18.24a2.9,2.9,0,0,0-1.49-2.52L26.48,9A3,3,0,0,0,25,8.57ZM25,10a1.65,1.65,0,0,1,.78.2l12,6.76a1.47,1.47,0,0,1,.76,1.28V31.76A1.47,1.47,0,0,1,37.81,33l-12,6.76a1.6,1.6,0,0,1-1.56,0L12.19,33a1.47,1.47,0,0,1-.76-1.28V18.24A1.47,1.47,0,0,1,12.19,17l12-6.76A1.65,1.65,0,0,1,25,10Zm0,5.71a9.29,9.29,0,1,0,7.39,14.91l.48-.65-4.32-2.5-.39.42a4.29,4.29,0,1,1,0-5.78l.39.42L32.87,20l-.48-.65A9.26,9.26,0,0,0,25,15.71Zm0,1.43a7.77,7.77,0,0,1,5.68,2.51l-2,1.13a5.72,5.72,0,1,0,0,8.43l2,1.14A7.77,7.77,0,0,1,25,32.86a7.86,7.86,0,0,1,0-15.72Zm7.14,4.29v1.43H30.71v1.42h1.43v1.43H30.71v1.43h1.43v1.43h1.43V27.14H35v1.43h1.43V27.14h1.43V25.71H36.43V24.28h1.43V22.86H36.43V21.43H35v1.43H33.57V21.43Zm1.43,2.85H35v1.43H33.57Z" transform="translate(-0.26 -0.26)"/>
        </svg>
        </>
    );
  }
}

export default IconCsharpSVG;