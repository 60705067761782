import React, { Component } from 'react';

class MarkupSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" className="programming-lang-svg" viewBox="0 0 24 24">
            <path d="M 12.890625 3 C 12.383625 3 11.958344 3.3817656 11.902344 3.8847656 L 10.123047 19.896484 C 10.057047 20.485484 10.517375 21 11.109375 21 C 11.615375 21 12.041656 20.618234 12.097656 20.115234 L 13.876953 4.1035156 C 13.942953 3.5145156 13.482625 3 12.890625 3 z M 5.7070312 6.7070312 C 5.4510313 6.7070312 5.195 6.805 5 7 L 0.70703125 11.292969 C 0.31603125 11.683969 0.31603125 12.317031 0.70703125 12.707031 L 5 17 C 5.39 17.39 6.0240625 17.39 6.4140625 17 C 6.8040625 16.61 6.8040625 15.975938 6.4140625 15.585938 L 2.828125 12 L 6.4140625 8.4140625 C 6.8050625 8.0240625 6.8050625 7.39 6.4140625 7 C 6.2190625 6.805 5.9630312 6.7070312 5.7070312 6.7070312 z M 18.292969 6.7070312 C 18.036969 6.7070312 17.780938 6.805 17.585938 7 C 17.195937 7.39 17.195937 8.0240625 17.585938 8.4140625 L 21.171875 12 L 17.585938 15.585938 C 17.195937 15.975938 17.195937 16.61 17.585938 17 C 17.975938 17.39 18.61 17.39 19 17 L 23.292969 12.707031 C 23.683969 12.316031 23.683969 11.682969 23.292969 11.292969 L 19 7 C 18.805 6.805 18.548969 6.7070312 18.292969 6.7070312 z">
            </path>
        </svg>
        </>
    );
  }
}

export default MarkupSVG;