import React, { Component } from 'react';

class MessagingSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 428.84 491.94" id="messaging">
            <defs>
                <linearGradient id="messaging-linear-gradient" x1="92.51" y1="328.78" x2="105.8" y2="333.95" gradientUnits="userSpaceOnUse">
                    <stop offset="0.42" stopColor="#cfb26c"/>
                    <stop offset="0.47" stopColor="#cbb270"/>
                    <stop offset="0.73" stopColor="#edd99a"/>
                    <stop offset="0.99" stopColor="#ecd798"/>
                </linearGradient>
                <clipPath id="messaging-path" transform="translate(-25.36 -21.87)">
                    <rect className="messaging-1" x="139.71" y="57.43" width="199" height="421.71" rx="25.07"/>
                </clipPath>
                <clipPath id="messaging-path-2" transform="translate(-25.36 -21.87)">
                    <circle className="messaging-2" cx="96.57" cy="341.86" r="39.29"/>
                </clipPath>
                <clipPath id="messaging-path-3" transform="translate(-25.36 -21.87)">
                    <path className="messaging-3" d="M138.73,398.56c-2.91-6.39-7.79-18.15-10.67-24.55-1.75-3.9-7.32-12.39-10.73-14.95-2.79-2.11-9.41-2.77-12.88-3.17-2-.23-7.92-3-7.92-3s-5.71,2.9-7.61,3c-3.31.11-9.49.7-12.25,2.53-4.4,2.91-9.61,13.13-12.15,17.75-3.17,5.74-10.35,22.66-10.35,22.66a43.07,43.07,0,0,0-1.59,5,9.73,9.73,0,0,0,1.16,5C56.89,414,67.58,427.4,67.58,427.4h58.26s10-13.73,13.15-18.91a10.84,10.84,0,0,0,1.58-4.43A22.82,22.82,0,0,0,138.73,398.56Zm-70-3.87,4.79-8.07,5.19,9.56Zm46.2,1.67,5.19-9.56,4.43,8.07Z"/>
                </clipPath>
                <clipPath id="messaging-path-4" transform="translate(-25.36 -21.87)">
                    <path className="cls-4" d="M120.14,381.29a67.41,67.41,0,0,1,.68,7.41c-.35,2.43-3.85,5.85-3.85,5.85H76.56s-3.5-3.42-3.86-5.85a69.6,69.6,0,0,1,.69-7.41l-9.82-5.44s4.85-9.51,7-12.36A27.32,27.32,0,0,1,75.35,359c1-.66,3.69-1.9,3.69-1.9l3.09-.63c0,.32,0,1.37,0,1.67,0,11.12,6,13.86,14.31,13.86s14.34-2.86,14.34-14c0-.28,0-1.32,0-1.61,0,0,2.55.87,3.38,1.06a19.93,19.93,0,0,1,4.43,1.9,23.44,23.44,0,0,1,4.44,4.12c2.19,2.79,7,12.36,7,12.36Z"/>
                </clipPath>
                <clipPath id="messaging-path-5" transform="translate(-25.36 -21.87)">
                    <path className="messaging-3" d="M91.22,349a7,7,0,0,1-.28,4.49,7.47,7.47,0,0,1-2.4,3,9.06,9.06,0,0,1-2.41,1.33l19.53.29a5.28,5.28,0,0,1-2.58-1.91,7.27,7.27,0,0,1-1-2.87,22.66,22.66,0,0,1-.38-4Z"/>
                </clipPath>
                <radialGradient id="messaging-radial-gradient" cx="84.31" cy="316.94" r="0.32" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#bcbec0"/>
                    <stop offset="0.19" stopColor="#b5b7b9"/>
                    <stop offset="0.48" stopColor="#a0a2a4"/>
                    <stop offset="0.83" stopColor="#7f8083"/>
                    <stop offset="1" stopColor="#6d6e71"/>
                </radialGradient>
                <radialGradient id="messaging-radial-gradient-2" cx="57.35" cy="317.09" r="0.32" xlinkHref="#messaging-radial-gradient"/>
                <clipPath id="messaging-path-6" transform="translate(-25.36 -21.87)">
                    <path className="messaging-5" d="M105.3,350.52a6.7,6.7,0,0,1-.33-3.24c.24-1.52.88-2.17,2.07-4.36a34.33,34.33,0,0,0,1.76-3.78c.2.29.54.7.93.66h.08c.26-.08.66-.61,2-4.18l.2-.56c.28-.8.43-1.23.53-1.61a4,4,0,0,0,.13-2.13c-.09-.33-.33-1.2-.94-1.35s-1.15.39-1.41.73c-.12.14-.11.18-.15.19-.57.07-1.21-7.94-3.78-9.85a30.68,30.68,0,0,0-3.25-2.48h0l-.39-3.82c1-.24,5.13-1.15,8.61,1.24s4,6.46,4.39,9.37A20.8,20.8,0,0,1,113.88,337a44.71,44.71,0,0,0-3.34,7,16.24,16.24,0,0,0-.87,2.76c-.21,1.18-.42,5.16,4,10.21a10.14,10.14,0,0,1-6.42-2.94A8.34,8.34,0,0,1,105.3,350.52Z"/>
                </clipPath>
                <linearGradient id="messaging-linear-gradient-3" x1="89.22" y1="334.21" x2="101.1" y2="338.17" gradientUnits="userSpaceOnUse">
                    <stop offset="0.01" stopColor="#ecd798"/>
                    <stop offset="0.1" stopColor="#edd99a"/>
                    <stop offset="0.48" stopColor="#cfb26c"/>
                </linearGradient>
                <linearGradient id="messaging-linear-gradient-4" x1="117.36" y1="345.95" x2="117.36" y2="345.95" xlinkHref="#messaging-linear-gradient-3"/>
                <clipPath id="messaging-path-7" transform="translate(-25.36 -21.87)">
                    <circle className="messaging-2" cx="383" cy="186.79" r="39.29"/>
                </clipPath>
                <clipPath id="messaging-path-8" transform="translate(-25.36 -21.87)">
                    <rect className="messaging-6" x="153.14" y="313.21" width="38.64" height="11"/>
                </clipPath>
            </defs>
            <g id="Other_Messages" data-name="Other Messages">
                <rect className="messaging-7" x="301.94" y="211.7" width="88.85" height="57.21" rx="8.46"/>
                <rect className="messaging-2" x="55.8" y="166.42" width="88.77" height="73.22" rx="8.99"/>
                <path className="messaging-7" d="M213.74,28.43h-154l.13-.15-34.56.15,13.5,14.34v48A9.14,9.14,0,0,0,48,99.86H213.74a9.14,9.14,0,0,0,9.12-9.12V37.54A9.14,9.14,0,0,0,213.74,28.43Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-2" d="M454.19,418.86l-22.34-.09a7.57,7.57,0,0,0-1-.06h-133a9,9,0,0,0-9,9v57.15a9,9,0,0,0,9,9h133a9,9,0,0,0,9-9V434.05Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-8">
                    <line className="messaging-9" x1="374.93" y1="230.13" x2="310.36" y2="230.13"/>
                    <line className="messaging-9" x1="374.93" y1="247.2" x2="310.36" y2="247.2"/>
                </g>
                <g className="messaging-8">
                    <line className="messaging-10" x1="397.64" y1="418.5" x2="292.43" y2="418.5"/>
                    <line className="messaging-10" x1="397.64" y1="434.57" x2="292.43" y2="434.57"/>
                    <line className="messaging-10" x1="397.64" y1="450.64" x2="292.43" y2="450.64"/>
                </g>
                <g className="messaging-8">
                    <line className="messaging-10" x1="180.39" y1="187.7" x2="75.18" y2="187.7"/>
                    <line className="messaging-10" x1="180.39" y1="203.77" x2="75.18" y2="203.77"/>
                    <line className="messaging-10" x1="180.39" y1="219.84" x2="75.18" y2="219.84"/>
                </g>
                <g className="messaging-8">
                    <line className="messaging-9" x1="178.39" y1="25.6" x2="29.93" y2="25.6"/>
                    <line className="messaging-9" x1="178.39" y1="42.67" x2="29.93" y2="42.67"/>
                    <line className="messaging-9" x1="81.29" y1="59.63" x2="29.55" y2="59.63"/>
                </g>
            </g>
            <g id="Phone">
                <rect className="messaging-11" x="114.36" y="35.55" width="199" height="421.71" rx="25.07"/>
                <g className="messaging-12">
                    <rect className="messaging-13" x="120.84" y="222.83" width="241.84" height="269.63" transform="translate(-91.64 33.34) rotate(-11.47)"/>
                </g>
                <rect className="messaging-14" x="114.36" y="35.55" width="199" height="421.71" rx="25.07"/><path className="messaging-15" d="M155.09,36.55H275.54a0,0,0,0,1,0,0v5.79a9,9,0,0,1-9,9H164.09a9,9,0,0,1-9-9V36.55A0,0,0,0,1,155.09,36.55Z"/>
            </g>
            <g id="Messages">
            <rect className="messaging-16" x="154.54" y="111.41" width="260" height="107.36" rx="9"/>
            <polygon className="messaging-16" points="186.36 239.34 173.03 191.75 220.9 204 186.36 239.34"/><rect className="messaging-17" x="42" y="288.82" width="263.54" height="107.36" rx="9" transform="translate(322.18 663.13) rotate(-180)"/>
            <polygon className="messaging-17" points="241.53 394.88 256.1 347.29 203.76 359.54 241.53 394.88"/><circle className="messaging-2" cx="71.21" cy="319.98" r="39.29"/>
            <g className="messaging-18">
                <path className="messaging-3" d="M138.73,398.56c-2.91-6.39-7.79-18.15-10.67-24.55-1.75-3.9-7.32-12.39-10.73-14.95-2.79-2.11-9.41-2.77-12.88-3.17-2-.23-7.92-3-7.92-3s-5.71,2.9-7.61,3c-3.31.11-9.49.7-12.25,2.53-4.4,2.91-9.61,13.13-12.15,17.75-3.17,5.74-10.35,22.66-10.35,22.66a43.07,43.07,0,0,0-1.59,5,9.73,9.73,0,0,0,1.16,5C56.89,414,67.58,427.4,67.58,427.4h58.26s10-13.73,13.15-18.91a10.84,10.84,0,0,0,1.58-4.43A22.82,22.82,0,0,0,138.73,398.56Zm-70-3.87,4.79-8.07,5.19,9.56Zm46.2,1.67,5.19-9.56,4.43,8.07Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-19">
                    <path className="messaging-20" d="M94.67,353.93a2.48,2.48,0,0,1-.85,2.22c-1.55,1.31-6.35,1.72-7.89,1.72s-6.56.43-6.56.43l-.82-2.66,15.75-2.08" transform="translate(-25.36 -21.87)"/>
                </g>
                <path className="messaging-4" d="M120.14,381.29a67.41,67.41,0,0,1,.68,7.41c-.35,2.43-3.85,5.85-3.85,5.85H76.56s-3.5-3.42-3.86-5.85a69.6,69.6,0,0,1,.69-7.41l-9.82-5.44s4.85-9.51,7-12.36A27.32,27.32,0,0,1,75.35,359c1-.66,3.69-1.9,3.69-1.9l3.09-.63c0,.32,0,1.37,0,1.67,0,11.12,6,13.86,14.31,13.86s14.34-2.86,14.34-14c0-.28,0-1.32,0-1.61,0,0,2.55.87,3.38,1.06a19.93,19.93,0,0,1,4.43,1.9,23.44,23.44,0,0,1,4.44,4.12c2.19,2.79,7,12.36,7,12.36Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-21">
                    <polygon className="messaging-22" points="95.18 360.4 98.87 363.05 92.88 341.2 95.18 360.4"/>
                    <polygon className="messaging-22" points="47.76 360.37 44.06 363.02 50.05 341.17 47.76 360.37"/>
                </g>
                <path className="messaging-23" d="M86.12,347c-.56,1.83.88,4.35,2.19,5.74A9.8,9.8,0,0,0,92,354.93c2.91,1.15,6.89,2.72,10.42.67a8.93,8.93,0,0,0,3.92-5.45c.14-.62.68-3-.29-4.68C103.13,340.41,87.56,342.23,86.12,347Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-23" d="M109.1,353.09c.42,1.59-3,3.76-3.53,4.11a5.54,5.54,0,0,0-1.06-4.11c-1.22-1.38-3.06-1.38-3-1.58C101.54,351.14,108.54,351,109.1,353.09Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-24" d="M88.07,350.94" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-23" d="M87.67,352.87a17.34,17.34,0,0,1-2.11,1.62,14,14,0,0,1-4.21,1.78,9,9,0,0,0,1.56-5.79c-.55-4.6-5.4-6.19-4.9-8.89.44-2.37,4.73-4.38,7.55-3.35C90.42,340,90.21,350.72,87.67,352.87Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-3" d="M91.22,349a7,7,0,0,1-.28,4.49,7.47,7.47,0,0,1-2.4,3,9.06,9.06,0,0,1-2.41,1.33l19.53.29a5.28,5.28,0,0,1-2.58-1.91,7.27,7.27,0,0,1-1-2.87,22.66,22.66,0,0,1-.38-4Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-25">
                    <polygon className="messaging-20" points="62.71 333.75 69.42 332.34 69.55 328.35 62.71 326.68 62.71 333.75"/>
                    <path className="messaging-20" d="M94.77,353.84a2.49,2.49,0,0,1-.84,2.22c-1.55,1.31-6.35,1.72-7.9,1.72s-6.55.43-6.55.43l-.83-2.66,15.75-2.08" transform="translate(-25.36 -21.87)"/>
                    <path className="messaging-26" d="M104.92,349c0,1.68-4.39,3.25-6.53,3.25s-5.87-1.57-5.87-3.25,1.73-3,3.87-3S104.92,347.35,104.92,349Z" transform="translate(-25.36 -21.87)"/>
                </g>
                <path className="messaging-20" d="M82.09,330.66c-.8-.77-1.24-.75-1.44-.7-.77.19-1,1.58-1,1.84-.19,1.17.19,1.83,1.3,4.66,1,2.42,1.21,3.31,1.78,3.34s.94-.67,1.12-1C85.35,336.32,84,332.5,82.09,330.66Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-27" d="M80.26,332.83c.11-.51,0-1.51.62-2" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-27" d="M81.59,331.47a1.6,1.6,0,0,0-.84-.77c-.1,0-.12,0-.3-.07" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-20" d="M112.81,331.8c0-.26-.27-1.65-1-1.84-.2,0-.63-.07-1.43.7-1.9,1.84-3.27,5.66-1.74,8.17.18.3.61,1,1.13,1s.83-.92,1.78-3.34C112.62,333.63,113,333,112.81,331.8Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-3" d="M110.51,329.34v.14h0v-.15Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-3" d="M110.51,329.49h0v0Z" transform="translate(-25.36 -21.87)"/><path className="messaging-3" d="M110.51,320.8v8.56c-.37,1.18-1.12,3.05-6.75,8.56a9,9,0,0,1,1.53,5,9.51,9.51,0,0,1-.16,1.7l5.38-15.13v.25a31,31,0,0,1-1.23,7.93,31.71,31.71,0,0,1-4.82,9.54,15.25,15.25,0,0,1-1.63,1.89,9.84,9.84,0,0,1-1.66,1.37A9,9,0,0,1,90.75,350h0A8.68,8.68,0,0,1,89,348.16a28.46,28.46,0,0,1-7.08-18.82l5.87,16.47a9,9,0,0,1,1-7.87,14.85,14.85,0,0,1-6.8-8.6,1.92,1.92,0,0,1-.09-.32v-7.9A15.28,15.28,0,0,1,83,318.3a14.7,14.7,0,0,1,3.42-4.39c2.62-2.31,6-1.2,9.78-1.2s5,1.44,7.62,3.64c1.5,1.26,4.77.19,5.67,1.95A14.33,14.33,0,0,1,110.51,320.8Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-3" d="M110.51,329.36v.13l-5.38,15.13a9.51,9.51,0,0,0,.16-1.7,9,9,0,0,0-1.53-5C109.39,332.41,110.14,330.54,110.51,329.36Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-3" d="M87.79,345.81l-5.87-16.47V329a1.92,1.92,0,0,0,.09.32,14.85,14.85,0,0,0,6.8,8.6,9,9,0,0,0-1,7.87Z" transform="translate(-25.36 -21.87)"/>
                <line className="messaging-6" x1="62.06" y1="323.83" x2="62.06" y2="323.83"/>
                <line className="messaging-6" x1="56.52" y1="307.46" x2="56.52" y2="307.46"/>
                <path className="messaging-27" d="M82,329.34" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-20" d="M85.61,328.32c.27,2.26.57,2.33.53,3.7-.1,2.71-1.32,3.23-1.14,5.29,0,.6.67,1.74,1.92,4,1.15,2.07,2,3.39,3.16,5.26A20.08,20.08,0,0,0,92,349.33a9.47,9.47,0,0,0,5.06,2.55,9.85,9.85,0,0,1-4.87-.93c-2.12-1-3.33-2.6-4.68-4.39A23.91,23.91,0,0,1,85.3,343,19.29,19.29,0,0,1,83,337c-.49-3.11.32-2.89.22-6.6s-.55-4.66.45-6.88c2.28-5,7.81-6.64,7.79-6.67s-4.73,1.81-5.89,5.82C85.16,324.14,85.29,325.63,85.61,328.32Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-20" d="M117.75,353.89" transform="translate(-25.36 -21.87)"/>
                <circle className="messaging-28" cx="84.35" cy="316.97" r="0.32"/>
                <circle className="messaging-29" cx="57.38" cy="317.13" r="0.32"/>
                <path className="messaging-30" d="M102.79,314.72c-.42-.64-3.49-5.12-9.05-5.64a12,12,0,0,0-11.19,5.55A9.27,9.27,0,0,0,79,317.21a9.67,9.67,0,0,0-2.1,4.78,12.32,12.32,0,0,0,1.15,7.55,8.37,8.37,0,0,1-1.25,3.35c-.92,1.39-1.72,1.59-2.67,2.86a8.82,8.82,0,0,0-1.63,4.5c-.08.82-.44,4.23,1.63,6.5,1.29,1.42,2.09.81,4.68,3a6.23,6.23,0,0,1,2.39,3.16,5.21,5.21,0,0,1-.88,4c.89-.46,3.36-1.9,3.82-4.4.27-1.48-.41-2.93-1.72-5.83-1.22-2.69-1.62-2.7-1.72-3.82-.21-2.27,1.37-3,2.25-5.85.8-2.62-.18-3.17,0-7.25.19-3.45.32-5.7,1.83-7.89,3-4.33,9-5,9.44-5.08l.4,0a15.46,15.46,0,0,1,8.51,1.81Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-5" d="M105.3,350.52a6.7,6.7,0,0,1-.33-3.24c.24-1.52.88-2.17,2.07-4.36a34.33,34.33,0,0,0,1.76-3.78c.2.29.54.7.93.66h.08c.26-.08.66-.61,2-4.18l.2-.56c.28-.8.43-1.23.53-1.61a4,4,0,0,0,.13-2.13c-.09-.33-.33-1.2-.94-1.35s-1.15.39-1.41.73c-.12.14-.11.18-.15.19-.57.07-1.21-7.94-3.78-9.85a30.68,30.68,0,0,0-3.25-2.48h0l-.39-3.82c1-.24,5.13-1.15,8.61,1.24s4,6.46,4.39,9.37A20.8,20.8,0,0,1,113.88,337a44.71,44.71,0,0,0-3.34,7,16.24,16.24,0,0,0-.87,2.76c-.21,1.18-.42,5.16,4,10.21a10.14,10.14,0,0,1-6.42-2.94A8.34,8.34,0,0,1,105.3,350.52Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-31">
                    <path className="messaging-30" d="M110.52,327.4c0-5.6-1.08-9-5.21-9.33-3.13-.26-5.71,3.11-5.71,8.52s2.08,10.6,5.22,10.6S110.52,332.8,110.52,327.4Z" transform="translate(-25.36 -21.87)"/></g><path className="cls-32" d="M110.49,330.45" transform="translate(-25.36 -21.87)"/><path className="messaging-33" d="M103.18,318.54a9.87,9.87,0,0,0-5.32-3.91c-4.73-1.32-8.7,1.9-9.56,2.6a18.26,18.26,0,0,0-3.73,5h0a19.15,19.15,0,0,0-.8,6.15c.11,3.06.88,3.52.48,5.83a11.3,11.3,0,0,1-2.53,5.87,3.48,3.48,0,0,0-.86,1.25c-.38,1.05.06,2.12.78,3.69,1.22,2.67,2,3,2.3,4.09.47,1.41.16,3.75-3.61,7.76A9,9,0,0,0,80.5,351c-1.37-3.75-5-3.69-6-7.11-.79-2.86.85-6.22,1.91-8.41a15.8,15.8,0,0,0,2-4.82,15.12,15.12,0,0,0,0-4,16.53,16.53,0,0,1,2.83-9.17,14.53,14.53,0,0,1,6.43-5.66c.64-.28,6.11-2.54,11.47.24a12.37,12.37,0,0,1,3.65,2.9Z" transform="translate(-25.36 -21.87)"/>
                    <path className="messaging-34" d="M117.36,346" transform="translate(-25.36 -21.87)"/>
                </g>
                <circle className="messaging-2" cx="357.64" cy="164.91" r="39.29"/>
                <g className="messaging-35">
                    <path className="messaging-36" d="M344.84,217.66c0-4.63,11.14-12,15.49-14.14,5-2.45,14.12-3,14.12-3l.08-8.14h0a15.17,15.17,0,0,1-5.21-7.08,4.24,4.24,0,0,1-1.44-.91c-.25-.24-.8-.86-1.3-3-.27-1.14-.78-3.3-.21-3.57.16-.08.47,0,1.13.61-.09-.31-.2-.77-.31-1.33a20.15,20.15,0,0,1,.36-8.65c.45-1.73,1.72-6.88,6.34-10.2a15,15,0,0,1,5.56-2.68c.29-.06,1.56-.13,4.07-.25,3.92-.2,6,.59,7,1.5.5.46-.06.2.06.57a5.22,5.22,0,0,1,1.57.27c4,1.31,5.28,7.31,6.1,11.08a33.88,33.88,0,0,1,.7,8.21c0,.64-.05.77-.08,1.14.79-.81.76-.54,1-.44.46.24.4,2.34.1,3.43-.38,1.42-.33,2.12-1.33,3.11a6.91,6.91,0,0,1-1.59.91l-4.92,7.33-.33,7.82s8.56.46,14.51,3.44c4.36,2.18,14.86,9.17,14.9,13.94.09,8.77-18.64,18.14-35.76,18.82C366,237.25,344.92,227,344.84,217.66Z" transform="translate(-25.36 -21.87)"/>
                </g>
                <line className="messaging-9" x1="169.94" y1="140.55" x2="301.94" y2="140.55"/>
                <line className="messaging-9" x1="171.65" y1="157.05" x2="303.65" y2="157.05"/>
                <line className="messaging-9" x1="171.65" y1="174.05" x2="303.65" y2="174.05"/>
                <line className="messaging-9" x1="171.65" y1="191.05" x2="242.79" y2="191.05"/>
                <path className="messaging-2" d="M339.71,52.58a45.36,45.36,0,0,1,1.19-4.66c.84-1.26,1.64-2.55,2.47-3.83l4.91-7.67,4.91-7.68,2.46-3.84c1.2-1,2.4-2.07,3.72-3-.31,1.61-.76,3.13-1.19,4.65l-2.46,3.84L350.81,38l-4.92,7.68c-.81,1.28-1.65,2.55-2.44,3.85A46.92,46.92,0,0,1,339.71,52.58Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-2" d="M348.54,61.47a23.44,23.44,0,0,1,1.7-2.73c.84-.45,1.65-.94,2.47-1.41l4.92-2.83,4.91-2.84L365,50.24a26,26,0,0,1,3.21-.12,28,28,0,0,1-1.71,2.72L364,54.25l-4.91,2.84-4.92,2.84c-.82.48-1.65.94-2.45,1.43A21.3,21.3,0,0,1,348.54,61.47Z" transform="translate(-25.36 -21.87)"/>
                <path className="messaging-2" d="M328.63,45.09a19.28,19.28,0,0,1-1-2.85c.2-.84.34-1.7.52-2.55l1-5.1,1-5.11.5-2.55a25.41,25.41,0,0,1,2-2.26,23.18,23.18,0,0,1,1,2.84l-.5,2.56-1,5.1-1,5.1c-.16.86-.35,1.7-.49,2.56A20.52,20.52,0,0,1,328.63,45.09Z" transform="translate(-25.36 -21.87)"/>
                <g className="messaging-37">
                    <circle className="dot" cx="132.48" cy="296.03" r="4.69"/>
                    <circle className="dot" cx="146.04" cy="296.03" r="4.69"/>
                    <circle className="dot" cx="159.61" cy="296.03" r="4.69"/>
                </g>
            </g>
        </svg>
        </>
    );
  }
}

export default MessagingSVG;