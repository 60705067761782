import React, { Component } from 'react';

class IconPythonSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.49 49.49">
            <circle className="language-icon-bg" cx="24.74" cy="24.74" r="24.74"/>
            <path className="language-icon-outline" d="M25,7.5c-3.09,0-5.18.74-6.51,1.81a4.63,4.63,0,0,0-1.86,3.59v3.73h7.61v.76H12.9a4.8,4.8,0,0,0-3.81,1.88A9.14,9.14,0,0,0,7.5,25a9.14,9.14,0,0,0,1.59,5.73,4.8,4.8,0,0,0,3.81,1.88h3.73V37.1a4.63,4.63,0,0,0,1.86,3.59c1.33,1.07,3.42,1.81,6.51,1.81s5.18-.74,6.51-1.81a4.63,4.63,0,0,0,1.86-3.59V33.37H25.76v-.76H37.1a4.8,4.8,0,0,0,3.81-1.88A9.14,9.14,0,0,0,42.5,25a9.14,9.14,0,0,0-1.59-5.73,4.8,4.8,0,0,0-3.81-1.88H33.37V12.9a4.63,4.63,0,0,0-1.86-3.62C30.18,8.22,28.09,7.5,25,7.5ZM25,9c2.85,0,4.58.67,5.56,1.45a3.09,3.09,0,0,1,1.29,2.43V22a2.28,2.28,0,0,1-2.28,2.28H20.43a3.82,3.82,0,0,0-3.49,2.33,3.71,3.71,0,0,0-.24.71,3.84,3.84,0,0,0-.07.76v3.05H12.9a3.16,3.16,0,0,1-2.59-1.31A7.57,7.57,0,0,1,9,25a7.57,7.57,0,0,1,1.29-4.78,3.16,3.16,0,0,1,2.59-1.31H25.76v-3.8H18.15V12.9a3.11,3.11,0,0,1,1.29-2.4C20.43,9.7,22.15,9,25,9ZM21.2,11.3a1.53,1.53,0,1,0,1.52,1.53A1.54,1.54,0,0,0,21.2,11.3Zm12.17,7.61H37.1a3.16,3.16,0,0,1,2.59,1.31A7.57,7.57,0,0,1,41,25a7.57,7.57,0,0,1-1.29,4.78,3.16,3.16,0,0,1-2.59,1.31H24.24v3.8h7.61V37.1a3.11,3.11,0,0,1-1.29,2.4C29.57,40.3,27.85,41,25,41s-4.57-.68-5.56-1.48a3.11,3.11,0,0,1-1.29-2.4V28a2.31,2.31,0,0,1,2.28-2.28h9.14a3.82,3.82,0,0,0,3.8-3.8ZM28.8,35.65a1.53,1.53,0,1,0,1.53,1.52A1.53,1.53,0,0,0,28.8,35.65Z" transform="translate(-0.26 -0.26)"/>
        </svg>
        </>
    );
  }
}

export default IconPythonSVG;