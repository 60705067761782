import React, { Component } from 'react';

class IconFoundationsSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.82 195.82">
            <circle className="icon-background" cx="97.91" cy="97.91" r="97.91"/>
            <path className="icon-highlight" d="M78.1,44.2c-2.7,0-5.2,1-7.2,2.8L53.4,63.8c-1.9,1.8-3,4.2-3,6.8V147c0.1,3.3,2.8,5.9,6.1,5.8h81c3.3,0,6-2.5,6.1-5.8V50c-0.1-3.3-2.8-5.8-6.1-5.8H78.1z"/>
            <path className="icon-highlight-foundations" d="M73.45,41a10.32,10.32,0,0,0-7.16,2.84L48.77,60.62a9.39,9.39,0,0,0-3,6.85v76.26a6,6,0,0,0,6.08,5.81h81a6,6,0,0,0,6.07-5.81V46.81A6,6,0,0,0,132.87,41Z" transform="translate(-2.09 -2.57)"/>
            <path className="icon-outline" d="M80.15,41a10.42,10.42,0,0,0-7.45,3.09L54.46,62.33a10.46,10.46,0,0,0-3.09,7.45v82.9A6.33,6.33,0,0,0,57.69,159H142a6.33,6.33,0,0,0,6.32-6.32V47.32A6.33,6.33,0,0,0,142,41Zm.72,4.21H142a2.11,2.11,0,0,1,2.1,2.11V152.68a2.11,2.11,0,0,1-2.1,2.11H57.69a2.12,2.12,0,0,1-2.11-2.11V70.5h19a6.33,6.33,0,0,0,6.32-6.32Zm-4.21,1.06V64.18a2.12,2.12,0,0,1-2.11,2.11H56.64a5.72,5.72,0,0,1,.8-1L75.68,47.07A5.72,5.72,0,0,1,76.66,46.27Zm29.17,28.47a2.09,2.09,0,0,0-1.72,1.57L91.47,126.88A2.1,2.1,0,0,0,93,129.43a1.89,1.89,0,0,0,.51.07,2.08,2.08,0,0,0,2-1.59L108.2,77.34a2.1,2.1,0,0,0-1.53-2.56A2,2,0,0,0,105.83,74.74Zm-25,12.62a2.11,2.11,0,0,0-1.49.61L66.74,100.62a2.09,2.09,0,0,0,0,3l12.64,12.64a2.11,2.11,0,1,0,3-3L71.21,102.11,82.36,91a2.1,2.1,0,0,0-1.49-3.59Zm37.93,0A2.1,2.1,0,0,0,117.31,91l11.15,11.16-11.15,11.15a2.11,2.11,0,1,0,3,3l12.64-12.64a2.11,2.11,0,0,0,0-3L120.29,88A2.11,2.11,0,0,0,118.8,87.36Z" transform="translate(-2.09 -2.57)"/>
        </svg>
        </>
    );
  }
}

export default IconFoundationsSVG;