import React, { Component } from 'react';

class IconDesignSVG extends Component {
  render() {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.82 195.82">
            <circle className="icon-background" cx="97.91" cy="97.91" r="97.91"/>
            <path className="icon-highlight" d="M50,57.31c-2.87,0-3.19,1.34-3.2,4.2V122c0,2.87.33,4.2,3.2,4.21h40.9v9.93H85.8c-2.29,0-4.48,1.93-6.09,3.55l-1.9,1.9c-.7.67-1.72,1.77-1.06,2.46.33.35-1.2.55-.72.54h46.58a1.72,1.72,0,0,0,1.78-1.68c0-.5-1.19-1-1.56-1.32l-3.9-3.9c-1.62-1.63-1.83-1.55-4.14-1.55h-5.08v-9.93h40.9c2.87,0,3.2-1.34,3.2-4.21V60.51c0-2.86-.33-3.19-3.2-3.2Z"/>
            <rect className="icon-highlight-design" x="37.98" y="55.33" width="110.01" height="64.86" rx="3.1"/>
            <path className="icon-outline" d="M62.66,71.86a5.22,5.22,0,0,0-5.2,5.2v34.7a5.22,5.22,0,0,0,5.2,5.2H80a5.21,5.21,0,0,0,5.2-5.2V77.06a5.21,5.21,0,0,0-5.2-5.2Zm34.7,0a5.22,5.22,0,0,0-5.21,5.2v34.7a5.22,5.22,0,0,0,5.21,5.2h45.1a5.21,5.21,0,0,0,5.2-5.2V77.06a5.21,5.21,0,0,0-5.2-5.2Zm-34.7,3.47H80a1.74,1.74,0,0,1,1.73,1.73v29.49H60.93V77.06A1.74,1.74,0,0,1,62.66,75.33Zm34.7,0h39.89V85.74a1.74,1.74,0,0,0,3.47,0V75.33h1.74a1.74,1.74,0,0,1,1.73,1.73v34.7a1.74,1.74,0,0,1-1.73,1.73h-1.74V103.08a1.74,1.74,0,0,0-3.47,0v10.41H97.36a1.74,1.74,0,0,1-1.74-1.73V77.06A1.74,1.74,0,0,1,97.36,75.33ZM69.6,77.06a1.74,1.74,0,0,0,0,3.47h3.47a1.74,1.74,0,1,0,0-3.47ZM99.09,90.94a1.73,1.73,0,0,0-1.73,1.74v3.46a1.74,1.74,0,1,0,3.47,0V92.68A1.73,1.73,0,0,0,99.09,90.94Zm39.9,0a1.73,1.73,0,0,0-1.74,1.74v3.46a1.74,1.74,0,1,0,3.47,0V92.68A1.73,1.73,0,0,0,139,90.94ZM60.93,110H81.74v1.74A1.74,1.74,0,0,1,80,113.49H62.66a1.74,1.74,0,0,1-1.73-1.73Z" transform="translate(-2.09 -2.57)"/>
            <path className="icon-outline" d="M52.25,57.9a5.22,5.22,0,0,0-5.2,5.2v62.45a5.22,5.22,0,0,0,5.2,5.21h39.9v6.93H88.09A8.63,8.63,0,0,0,82,140.24l-4.9,4.9a1.74,1.74,0,0,0,1.22,3h48.58a1.73,1.73,0,0,0,1.22-3l-4.9-4.9a8.63,8.63,0,0,0-6.14-2.55H113v-6.93h39.9a5.22,5.22,0,0,0,5.2-5.21V63.1a5.22,5.22,0,0,0-5.2-5.2Zm0,3.47H152.87a1.74,1.74,0,0,1,1.73,1.73v62.45a1.74,1.74,0,0,1-1.73,1.74H52.25a1.74,1.74,0,0,1-1.73-1.74V63.1A1.74,1.74,0,0,1,52.25,61.37Zm43.37,69.39H109.5v6.93H95.62Zm-7.53,10.4H117a5.23,5.23,0,0,1,3.68,1.53l2,1.94H82.46l2-1.94A5.24,5.24,0,0,1,88.09,141.16Z" transform="translate(-2.09 -2.57)"/>
        </svg>
        </>
    );
  }
}

export default IconDesignSVG;
